<template>
  <div class="ui grid container-3">
    <div class="sixteen wide column">
      <div class="instadetails">
        <div class="headerdetails">
          <h5>QUICK STEPS TO</h5>
          <h1>CASH IN <span>VIA INSTAPAY</span></h1>
          <p>Instantly top up your wallet with funds from your Bank or Other E-Wallet Accounts</p>
        </div>

        <div class="stepsdetails">
          <div class="step">
            <div class="item">Step 1</div>
            <div class="item">2</div>
            <div class="item">3</div>
          </div>
          <div class="details">
            <div class="item">Open your bank or other e-wallet app</div>
            <div class="item">Follow the steps of your bank or other e-wallet to fund TayoCash wallet</div>
            <div class="item">Open you TayoCash app to see new wallet balance </div>
          </div>
          <div class="buttons">
            <a class="yellow" href="https://assets.prod.tayocash.com/forms/list_of_instapay_participating_banks.pdf" download target="_blank">DOWNLOAD LIST OF BANKS AND EMIs</a>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/wn3.png">
  </div>
</template>

<script>
export default {
  name: 'content3'
}
</script>

<style scoped lang='scss'></style>
